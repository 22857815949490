<template>
  <Footer class="admin-footer">
    <a-row class="admin-footer__wrapper" justify="center">
      <!--<a-col :lg="6" :md="12" :xs="24" class="admin-footer__logo-button admin-footer__inner">
        <a class="striking-logo" :href="websiteUrl">
          <img :src="require('../static/img/logo.svg')" alt="logo" />
        </a>
        <sdButton size="default" type="primary" @click="changeModal">
          {{ i18n.t('support.writeUs') }}
        </sdButton>
      </a-col>-->
      <!--<a-col :lg="6" :md="12" :xs="24" class="admin-footer__inner">
        <p class="admin-footer__title">{{ i18n.t('footer.contacts') }}</p>
        <div class="admin-footer__contacts admin-footer__links">
          <p><sdFeatherIcons size="18" type="map-pin" /><a> 221b, Baker Street, London, NW1 6XE, United Kingdom</a></p>
          <p><sdFeatherIcons size="18" type="phone" /> <a :href="`tel:${phoneEngl}`">+44-123-456-78-90</a></p>
          <p>
            <sdFeatherIcons size="18" type="mail" /><a :href="`mailto:${supportEmail}`"> {{ supportEmail }} </a>
          </p>
          <p>
            <sdFeatherIcons size="18" type="help-circle" />
            <a href="tel:+74950454392"> +7-495-045-43-92 </a>
          </p>
        </div>
      </a-col>
      <a-col :lg="6" :md="12" :xs="24" class="admin-footer__inner">
        <p class="admin-footer__title">{{ i18n.t('footer.usefulLinks') }}</p>
        <div class="admin-footer__links">
          <ul>
            <li>
              <a href="https://mthub.io/" target="_blank">
                {{ i18n.t('footer.news') }}
              </a>
            </li>
            <li>
              <a href="./pdfs/free.pdf" target="_blank">
                {{ i18n.t('footer.clientAgreement') }}
              </a>
            </li>
            <li>
              <a href="./pdfs/free.pdf" target="_blank">
                {{ i18n.t('footer.privacyPolicy') }}
              </a>
            </li>
          </ul>
        </div>
      </a-col>-->
      <div class="admin-footer__inner">
        <!--<p class="admin-footer__title" v-if="collapsed">{{ i18n.t('footer.connectUs') }}</p>-->
        <div class="admin-footer__links" v-if="locale == 'ru'">
          <a href="https://t.me/mthub_ru" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/telegram.svg`)" alt="logo" />
          </a>
          <!--<a href="https://www.facebook.com/MTHUB-Russian-Community-101880425871200" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/facebook.svg`)" alt="logo" />
          </a>-->
          <!--<a href="https://vc.ru/u/1209117-mthub" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/link.svg`)" alt="logo" />
          </a>-->
          <!--<a href="https://medium.com/@mthub" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/medium.svg`)" alt="logo" />
          </a>-->
        </div>
        <div class="admin-footer__links" v-else>
          <!--<a href="https://www.facebook.com/mthubofficial" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/facebook.svg`)" alt="logo" />
          </a>-->
          <!--<a href="https://medium.com/@mthub" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/medium.svg`)" alt="logo" />
          </a>-->
          <a href="https://t.me/mthubnews" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/telegram.svg`)" alt="logo" />
          </a>
          <a href="https://twitter.com/MTHUB_Official" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/twitter.svg`)" alt="logo" />
          </a>
          <a href="https://discord.com/invite/wX2Fug4AW8" target="_blank">
            <img class="admin-footer__connect-us" :src="require(`../static/img/footer/discord.svg`)" alt="logo" />
          </a>
        </div>
      </div>
      <a-col :md="24" :xs="24" class="admin-footer__footer" style="margin-top: 20px">
        <p>Copyright © 2022 MTHUB.</p>
        <a-select
          class="admin-footer__language"
          v-model:value="locale"
          @change="changeLanguage"
          :options="languages"
          ref="select"
        >
        </a-select>
      </a-col>
    </a-row>
    <!--<sdModal
      centered
      type="primary"
      :title="i18n.t('support.haveAnyQuestions')"
      :visible="visibleModal"
      :onCancel="changeModal"
      :width="300"
    >
      <a-form
        name="question"
        ref="formRef"
        :rules="rules"
        :model="formState"
        @finish="handleSubmit(formState)"
        layout="vertical"
      >
        <a-form-item ref="email" name="email" :label="i18n.t('authPage.email')">
          <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" />
        </a-form-item>
        <a-form-item ref="name" name="name" :label="i18n.t('authPage.fullName')">
          <a-input type="text" required v-model:value="formState.name" />
        </a-form-item>
        <a-form-item ref="phone" name="phone" :label="i18n.t('authPage.phone')">
          <a-input type="number" required v-model:value="formState.phone" />
        </a-form-item>
        <a-form-item ref="question" name="question" :label="i18n.t('authPage.message')">
          <a-textarea type="text" required v-model:value="formState.question" :rows="4" />
        </a-form-item>
        <sdButton type="primary" htmlType="submit" style="width:100%;" :disabled="isLoading">{{
          i18n.t('support.askQuestion')
        }}</sdButton>
      </a-form>
    </sdModal>-->
  </Footer>
</template>
<script>
import { Layout, message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref } from '@vue/runtime-core';
import { languages } from '@/config/helpers/JSONdata.js';
import { useStore } from 'vuex';
import { setCookie, getItem, setItem } from '@/utility/localStorageControl';

const { Footer } = Layout;

export default {
  name: 'WithAdminLayout',
  props: {
    authPage: Boolean,
  },
  components: {
    Footer,
  },

  setup(props) {
    const { dispatch, state } = useStore();
    const i18n = useI18n();
    const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;
    const phoneEngl = process.env.VUE_APP_SUPPORT_PHONE_ENGLAND;

    const locale = computed(() => i18n.locale.value);
    const personalData = computed(() => state.profile.data);
    const formState = ref({});
    const isLoading = ref(false);
    const visibleModal = ref(false);
    const collapsed = ref(window.innerWidth > 900);
    const rules = {
      email: [
        {
          required: true,
          message: i18n.t('authPage.errorEmail'),
          trigger: 'blur',
        },
      ],
      name: [
        {
          required: true,
          message: i18n.t('authPage.errorFirstName'),
          trigger: 'blur',
        },
      ],
      phone: [
        {
          required: true,
          message: i18n.t('authPage.errorPhone'),
          trigger: 'blur',
        },
      ],
      question: [
        {
          required: true,
          message: i18n.t('authPage.errorMessage'),
          trigger: 'blur',
        },
      ],
    };

    const changeModal = () => {
      visibleModal.value = !visibleModal.value;
    };

    const handleSubmit = () => {
      isLoading.value = true;
      setTimeout(() => (isLoading.value = false), 500);
      changeModal();
      message.success(i18n.t('messages.sendQuestion'));
    };

    const changeLanguage = (data) => {
      i18n.locale.value = data;
      if (!props.authPage) {
        const fromLocal = getItem('user');
        fromLocal.settings.locale = data;
        let time_zone = fromLocal.settings.time_zone;
        setItem('user', fromLocal);
        dispatch('axiosChangeSettings', { locale: data, time_zone });
      }
      setCookie({ name: 'language', value: data });
    };
    onMounted(() => {
      setTimeout(() => {
        if (personalData.value) {
          formState.value = {
            email: personalData.value.email,
            name: personalData.value.user_personal_data.last_name
              ? `${personalData.value.user_personal_data.last_name} ${personalData.value.user_personal_data.first_name} ${personalData.value.user_personal_data.middle_name}`
              : '',
            question: ' ',
            phone: personalData.value.phone ? personalData.value.phone : '',
          };
        }
      }, 1000);
    });

    return {
      i18n,
      locale,
      languages,
      visibleModal,
      isLoading,
      rules,
      formState,
      collapsed,
      supportEmail,
      phoneEngl,
      changeLanguage,
      changeModal,
      handleSubmit,
    };
  },
};
</script>
<style lang="scss">
.admin-footer {
  padding: 20px;
  font-size: 14px;
  width: 100%;
  margin-top: 30px;
  box-shadow: 0 -5px 10px rgba(146, 153, 184, 0.05);

  &__footer {
    p {
      margin: 0 auto;
      padding-left: 90px;
      @media screen and (max-width: 450px) {
        padding-left: 0px;
      }
    }
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__language {
    margin: 0;
    @media screen and (max-width: 450px) {
      margin-left: 30px;
    }
  }
  &__connect-us {
    width: 40px;
    height: 40px;
    filter: invert(51%) sepia(63%) saturate(1444%) hue-rotate(180deg) brightness(94%) contrast(90%);
    opacity: 0.6;
    transition: 0.2s linear;
    &:hover {
      transition: 0.2s linear;
      opacity: 1;
    }
  }
  &__inner {
    @media screen and(max-width:900px) {
      margin-right: 1px;
      margin-top: 40px;
    }
  }
}
</style>
