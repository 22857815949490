const languages = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'ru',
    label: 'Русский',
  },
];

const publicTypes = {
  public: 'published_accounts',
  invest: 'investor_accounts',
};

const zones = [
  {
    value: 'UTC-12',
    label: '(GMT - 12:00) Eniwetok, Kwajalein',
  },
  {
    value: 'UTC-11',
    label: '(GMT - 11:00) Midway Island, Samoa',
  },
  {
    value: 'UTC-10',
    label: '(GMT - 10:00) Hawaii',
  },
  {
    value: 'UTC-9',
    label: '(GMT - 9:00) Alaska',
  },
  {
    value: 'UTC-8',
    label: '(GMT - 8:00) Pacific Time (US & Canada)',
  },
  {
    value: 'UTC-7',
    label: '(GMT - 7:00) Mountain Time (US & Canada)',
  },
  {
    value: 'UTC-6',
    label: '(GMT - 6:00) Central Time (US & Canada), Mexico City',
  },
  {
    value: 'UTC-5',
    label: '(GMT - 5:00) Eastern Time (US & Canada), Bogota, Lima',
  },
  {
    value: 'UTC-4',
    label: '(GMT - 4:00) Atlantic Time (Canada), Caracas, La Paz',
  },
  {
    value: 'UTC-3.5',
    label: '(GMT - 3:30) Newfoundland',
  },
  {
    value: 'UTC-3',
    label: '(GMT - 3:00) Brazil, Buenos Aires, Georgetown',
  },
  {
    value: 'UTC-2',
    label: '(GMT - 2:00) Mid-Atlantic',
  },
  {
    value: 'UTC-1',
    label: '(GMT - 1:00) Azores, Cape Verde Islands',
  },
  {
    value: 'UTC',
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
  },
  {
    value: 'UTC+1',
    label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'UTC+2',
    label: '(GMT +2:00) Kaliningrad, South Africa',
  },
  {
    value: 'UTC+3',
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
  },
  {
    value: 'UTC+3.5',
    label: '(GMT +3:30) Tehran',
  },
  {
    value: 'UTC+4',
    label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
  },
  {
    value: 'UTC+4.5',
    label: '(GMT +4:30) Kabul',
  },
  {
    value: 'UTC+5',
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
  },
  {
    value: 'UTC+5.5',
    label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
  },
  {
    value: 'UTC+5.75',
    label: '(GMT +5:45) Kathmandu',
  },
  {
    value: 'UTC+6',
    label: '(GMT +6:00) Almaty, Dhaka, Colombo',
  },
  {
    value: 'UTC+7',
    label: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'UTC+8',
    label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
  },
  {
    value: 'UTC+9',
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
  },
  {
    value: 'UTC+9.5',
    label: '(GMT +9:30) Adelaide, Darwin',
  },
  {
    value: 'UTC+10',
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
  },
  {
    value: 'UTC+11',
    label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
  },
  {
    value: 'UTC+12',
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
  },
];
const walletCurrencies = [
  { label: 'USDT(ERC20)', value: 1 },
  { label: 'USDT(TRC20)', value: 2 },
  { label: 'BTC', value: 4 },
  { label: 'LTC', value: 5 },
  //{ label: 'BankCard', value: 6 },
];
const depositCurrencies = [
  { label: 'USDT(ERC20)', value: 'USDT.ERC20' },
  { label: 'USDT(TRC20)', value: 'USDT.TRC20' },
  { label: 'ETH', value: 'ETH' },
  { label: 'BTC', value: 'BTC' },
  { label: 'LTC', value: 'LTC' },
];

const reports = [
  {
    add_date: '28.11.2021',
    days: '22 по 28 ноября 2021',
    report_name: 'workOutcomes',
    action: '12',
  },
  {
    add_date: '21.11.2021',
    days: '15 по 21 ноября 2021',
    report_name: 'workOutcomes',
    action: '11',
  },
  {
    add_date: '14.11.2021',
    days: '8 по 14 ноября 2021',
    report_name: 'workOutcomes',
    action: '10',
  },
  {
    add_date: '7.11.2021',
    days: '1 по 7 ноября 2021',
    report_name: 'workOutcomes',
    action: '9',
  },
  {
    add_date: '31.10.2021',
    days: '25 по 31 октября 2021',
    report_name: 'workOutcomes',
    action: '8',
  },
  {
    add_date: '10.10.2021',
    days: '18 по 24 октября 2021',
    report_name: 'workOutcomes',
    action: '7',
  },
  {
    add_date: '10.10.2021',
    days: '3 по 10 октября 2021',
    report_name: 'workOutcomes',
    action: '6',
  },
  {
    add_date: '03.10.2021',
    days: '3 октября 2021',
    report_name: 'weekMonthOutcomes',
    action: '5',
  },
  {
    add_date: '26.09.2021',
    days: 'с 19 по 26 сентября',
    report_name: 'workOutcomes',
    action: '4',
  },
  {
    add_date: '19.09.2021',
    days: 'с 13 по 19 сентября',
    report_name: 'workOutcomes',
    action: '3',
  },
  {
    add_date: '12.09.2021',
    days: 'с 5 по 12 сентября',
    report_name: 'workOutcomes',
    action: '2',
  },
  {
    add_date: '01.09.2021',
    days: 'за август',
    report_name: 'workOutcomes',
    action: '1',
  },
];
const countries = [
  {
    value: 'AF',
  },
  {
    value: 'AX',
  },
  {
    value: 'AL',
  },
  {
    value: 'DZ',
  },
  {
    value: 'AS',
  },
  {
    value: 'AD',
  },
  {
    value: 'AO',
  },
  {
    value: 'AI',
  },
  {
    value: 'AQ',
  },
  {
    value: 'AG',
  },
  {
    value: 'AR',
  },
  {
    value: 'AM',
  },
  {
    value: 'AW',
  },
  {
    value: 'AU',
  },
  {
    value: 'AT',
  },
  {
    value: 'AZ',
  },
  {
    value: 'BS',
  },
  {
    value: 'BH',
  },
  {
    value: 'BD',
  },
  {
    value: 'BB',
  },
  {
    value: 'BY',
  },
  {
    value: 'BE',
  },
  {
    value: 'BZ',
  },
  {
    value: 'BJ',
  },
  {
    value: 'BM',
  },
  {
    value: 'BT',
  },
  {
    value: 'BO',
  },
  {
    value: 'BA',
  },
  {
    value: 'BW',
  },
  {
    value: 'BV',
  },
  {
    value: 'BR',
  },
  {
    value: 'IO',
  },
  {
    value: 'VG',
  },
  {
    value: 'BN',
  },
  {
    value: 'BG',
  },
  {
    value: 'BF',
  },
  {
    value: 'BI',
  },
  {
    value: 'KH',
  },
  {
    value: 'CM',
  },
  {
    value: 'CA',
  },
  {
    value: 'CV',
  },
  {
    value: 'BQ',
  },
  {
    value: 'KY',
  },
  {
    value: 'CF',
  },
  {
    value: 'TD',
  },
  {
    value: 'CL',
  },
  {
    value: 'CN',
  },
  {
    value: 'CX',
  },
  {
    value: 'CC',
  },
  {
    value: 'CO',
  },
  {
    value: 'KM',
  },
  {
    value: 'CG',
  },
  {
    value: 'CD',
  },
  {
    value: 'CK',
  },
  {
    value: 'CR',
  },
  {
    value: 'CI',
  },
  {
    value: 'HR',
  },
  {
    value: 'CU',
  },
  {
    value: 'CW',
  },
  {
    value: 'CY',
  },
  {
    value: 'CZ',
  },
  {
    value: 'DK',
  },
  {
    value: 'DJ',
  },
  {
    value: 'DM',
  },
  {
    value: 'DO',
  },
  {
    value: 'EC',
  },
  {
    value: 'EG',
  },
  {
    value: 'SV',
  },
  {
    value: 'GQ',
  },
  {
    value: 'ER',
  },
  {
    value: 'EE',
  },
  {
    value: 'SZ',
  },
  {
    value: 'ET',
  },
  {
    value: 'FK',
  },
  {
    value: 'FO',
  },
  {
    value: 'FJ',
  },
  {
    value: 'FI',
  },
  {
    value: 'FR',
  },
  {
    value: 'GF',
  },
  {
    value: 'PF',
  },
  {
    value: 'TF',
  },
  {
    value: 'GA',
  },
  {
    value: 'GM',
  },
  {
    value: 'GE',
  },
  {
    value: 'DE',
  },
  {
    value: 'GH',
  },
  {
    value: 'GI',
  },
  {
    value: 'GR',
  },
  {
    value: 'GL',
  },
  {
    value: 'GD',
  },
  {
    value: 'GP',
  },
  {
    value: 'GU',
  },
  {
    value: 'GT',
  },
  {
    value: 'GG',
  },
  {
    value: 'GN',
  },
  {
    value: 'GW',
  },
  {
    value: 'GY',
  },
  {
    value: 'HT',
  },
  {
    value: 'HM',
  },
  {
    value: 'HN',
  },
  {
    value: 'HK',
  },
  {
    value: 'HU',
  },
  {
    value: 'IS',
  },
  {
    value: 'IN',
  },
  {
    value: 'ID',
  },
  {
    value: 'IR',
  },
  {
    value: 'IQ',
  },
  {
    value: 'IE',
  },
  {
    value: 'IM',
  },
  {
    value: 'IL',
  },
  {
    value: 'IT',
  },
  {
    value: 'JM',
  },
  {
    value: 'JP',
  },
  {
    value: 'JE',
  },
  {
    value: 'JO',
  },
  {
    value: 'KZ',
  },
  {
    value: 'KE',
  },
  {
    value: 'KI',
  },
  {
    value: 'KW',
  },
  {
    value: 'KG',
  },
  {
    value: 'LA',
  },
  {
    value: 'LV',
  },
  {
    value: 'LB',
  },
  {
    value: 'LS',
  },
  {
    value: 'LR',
  },
  {
    value: 'LY',
  },
  {
    value: 'LI',
  },
  {
    value: 'LT',
  },
  {
    value: 'LU',
  },
  {
    value: 'MO',
  },
  {
    value: 'MG',
  },
  {
    value: 'MW',
  },
  {
    value: 'MY',
  },
  {
    value: 'MV',
  },
  {
    value: 'ML',
  },
  {
    value: 'MT',
  },
  {
    value: 'MH',
  },
  {
    value: 'MQ',
  },
  {
    value: 'MR',
  },
  {
    value: 'MU',
  },
  {
    value: 'YT',
  },
  {
    value: 'MX',
  },
  {
    value: 'FM',
  },
  {
    value: 'MD',
  },
  {
    value: 'MC',
  },
  {
    value: 'MN',
  },
  {
    value: 'ME',
  },
  {
    value: 'MS',
  },
  {
    value: 'MA',
  },
  {
    value: 'MZ',
  },
  {
    value: 'MM',
  },
  {
    value: 'NA',
  },
  {
    value: 'NR',
  },
  {
    value: 'NP',
  },
  {
    value: 'NL',
  },
  {
    value: 'NC',
  },
  {
    value: 'NZ',
  },
  {
    value: 'NI',
  },
  {
    value: 'NE',
  },
  {
    value: 'NG',
  },
  {
    value: 'NU',
  },
  {
    value: 'NF',
  },
  {
    value: 'KP',
  },
  {
    value: 'MK',
  },
  {
    value: 'MP',
  },
  {
    value: 'NO',
  },
  {
    value: 'OM',
  },
  {
    value: 'PK',
  },
  {
    value: 'PW',
  },
  {
    value: 'PS',
  },
  {
    value: 'PA',
  },
  {
    value: 'PG',
  },
  {
    value: 'PY',
  },
  {
    value: 'PE',
  },
  {
    value: 'PH',
  },
  {
    value: 'PN',
  },
  {
    value: 'PL',
  },
  {
    value: 'PT',
  },
  {
    value: 'PR',
  },
  {
    value: 'QA',
  },
  {
    value: 'RE',
  },
  {
    value: 'RO',
  },
  {
    value: 'RU',
  },
  {
    value: 'RW',
  },
  {
    value: 'WS',
  },
  {
    value: 'SM',
  },
  {
    value: 'ST',
  },
  {
    value: 'SA',
  },
  {
    value: 'SN',
  },
  {
    value: 'RS',
  },
  {
    value: 'SC',
  },
  {
    value: 'SL',
  },
  {
    value: 'SG',
  },
  {
    value: 'SX',
  },
  {
    value: 'SK',
  },
  {
    value: 'SI',
  },
  {
    value: 'SB',
  },
  {
    value: 'SO',
  },
  {
    value: 'ZA',
  },
  {
    value: 'GS',
  },
  {
    value: 'KR',
  },
  {
    value: 'SS',
  },
  {
    value: 'ES',
  },
  {
    value: 'LK',
  },
  {
    value: 'BL',
  },
  {
    value: 'SH',
  },
  {
    value: 'KN',
  },
  {
    value: 'LC',
  },
  {
    value: 'MF',
  },
  {
    value: 'PM',
  },
  {
    value: 'VC',
  },
  {
    value: 'SD',
  },
  {
    value: 'SR',
  },
  {
    value: 'SJ',
  },
  {
    value: 'SE',
  },
  {
    value: 'CH',
  },
  {
    value: 'SY',
  },
  {
    value: 'TW',
  },
  {
    value: 'TJ',
  },
  {
    value: 'TZ',
  },
  {
    value: 'TH',
  },
  {
    value: 'TL',
  },
  {
    value: 'TG',
  },
  {
    value: 'TK',
  },
  {
    value: 'TO',
  },
  {
    value: 'TT',
  },
  {
    value: 'TN',
  },
  {
    value: 'TR',
  },
  {
    value: 'TM',
  },
  {
    value: 'TC',
  },
  {
    value: 'TV',
  },
  {
    value: 'UM',
  },
  {
    value: 'VI',
  },
  {
    value: 'UG',
  },
  {
    value: 'UA',
  },
  {
    value: 'AE',
  },
  {
    value: 'GB',
  },
  {
    value: 'US',
  },
  {
    value: 'UY',
  },
  {
    value: 'UZ',
  },
  {
    value: 'VU',
  },
  {
    value: 'VA',
  },
  {
    value: 'VE',
  },
  {
    value: 'VN',
  },
  {
    value: 'WF',
  },
  {
    value: 'EH',
  },
  {
    value: 'YE',
  },
  {
    value: 'ZM',
  },
  {
    value: 'ZW',
  },
];
export { zones, languages, countries, walletCurrencies, depositCurrencies, reports, publicTypes };
